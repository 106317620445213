/* globals Sentry, define */
import 'core-js/fn/object/entries'
import 'core-js/fn/object/values'
import 'intersection-observer'
import requirejs from 'requirejs'
import {getRjsConfig} from './rjs-config'
import {_define, _import} from './rjs-utils'
import * as sharedRegistry from './init/sharedRegistry'
import {initBeatEvents} from './bi/initBeatEvents'
import {BEATS} from './bi/constants'
import experimentFactory from 'santa-main-r/lib/lib/common/experimentFactory'
import getQueryUtils from 'santa-main-r/lib/lib/common/getQueryUtils'
import addExperimentsFromQuery from 'santa-main-r/lib/lib/common/addExperimentsFromQuery'
import createLogger from './init/logger'
import createWorkerFactory from './init/platform/createWorker'
import createWorkerWrapperIframe from './init/platform/createWorkerWrapperIframe'
import {getPagesPlatformApplications} from './init/utils/pagesPlatformApplicationsUtils'
import iframesHandler from 'bolt-server/src/warmup/iframesHandler'
import {generateResourceHints, generateExtraResourceHints, getNavigationModelFromWarmupData} from './mainr-optimizations'

window.santaBase = `${window.boltBase}/node_modules/wix-santa`
const {rendererModel, publicModel, location, navigator, performance = {}, isStreaming, serviceTopology, wixBiSession = {}, boltBase, santaBase, documentServicesModel} = window
window.wixBiSession = wixBiSession

const queryUtil = getQueryUtils(window)
window.queryUtil = queryUtil
const {getParameterByName, isParameterTrue} = queryUtil

const {siteMetaData} = rendererModel
const {isResponsive} = siteMetaData
rendererModel.runningExperiments = addExperimentsFromQuery(rendererModel.runningExperiments, queryUtil, 'viewer')
rendererModel.pagesPlatformApplications = getPagesPlatformApplications(rendererModel)

// this is temporary until we fix LAYOUT-128 that prevents us from merging the experiment in bolt classic
if (isResponsive && !rendererModel.runningExperiments.hasOwnProperty('bv_wixDropdown')) {
    rendererModel.runningExperiments.bv_wixDropdown = 'new'
}
siteMetaData.adaptiveMobileOn = isResponsive ? false : siteMetaData.adaptiveMobileOn

const isPreview = typeof publicModel === 'undefined'
let cookie = '' // eslint-disable-line santa/no-module-state
let modelsFromHost = null // eslint-disable-line santa/no-module-state
try {
    cookie = document.cookie
} catch (ex) {
    console.log('Unable to get cookie from document. ', ex)
}
const requestModel = {
    userAgent: navigator.userAgent,
    cookie,
    deviceType: !isPreview && publicModel.deviceInfo ? publicModel.deviceInfo.deviceType : 'desktop'
}

const rawUrl = location.href
const isInSSR = false

const isLocal = getParameterByName('BoltSource').search(/^https?:\/\/localhost($|:|\/)/) === 0
const isDebug = Boolean(getParameterByName('debug')) ||
    isParameterTrue('ssrDebug') ||
    isParameterTrue('carmiDebug') ||
    isLocal

const qaParam = getParameterByName('isqa')
const isQA = !!qaParam && qaParam !== 'false'
const sentry = typeof Sentry !== 'undefined' ? Sentry : {}
const logger = createLogger(sentry, {
    rendererModel,
    isPreview,
    publicModel,
    requestModel,
    rawUrl,
    boltBase,
    isInSSR,
    wixBiSession,
    documentServicesModel,
    isDebug
})
logger.appLoadingPhaseStart('mainr_loading')
const experimentInst = experimentFactory.build(window)

const noop = () => {}

window.performance = performance
window.performance.mark = performance.mark || noop
window.performance.now = performance.now || (() => Date.now())

const santaPackagesToDebug = (getParameterByName('debug') || '').split(',').filter(Boolean)

const isDS = isParameterTrue('isEdited') || isParameterTrue('ds')
const startDSInteraction = isDS ? logger.interactionStarted : () => {}
const endDSInteraction = isDS ? logger.interactionEnded : () => {}

const rjsConfig = getRjsConfig(boltBase, santaBase, serviceTopology, {
    debug: isDebug,
    santaPackagesToDebug,
    isPreview,
    local: isLocal
}, experimentInst, logger)
requirejs.onError = error => {
    const {requireModules, requireType} = error
    logger.captureError(error, {tags: {requireModules, requireType}})
}

requirejs.config(rjsConfig)

define('mobx', [], () => ({
    runInAction: noop,
    action: noop,
    isObservableArray: noop
}))
define('mobx-react', [], () => ({
    observer: noop
}))

if (isPreview) {
    import(/*webpackChunkName: "custom-elements"*/ './init/customElements')
}

async function importGlobals() {
    window.React = await _import('react')
    window.ReactDOM = await _import('react-dom')
    window.coreUtilsLib = await _import('santa-core-utils')
}

async function initDocumentServicesDocument() {
    await importGlobals()
    const loadAllModules = Promise.all([
        import(/*webpackChunkName: "init"*/ './init'),
        import(/*webpackChunkName: "bolt-ds-adapter"*/'bolt-ds-adapter'),
        import(/*webpackChunkName: "bolt-ds-viewer-manager"*/'bolt-ds-viewer-manager'),
        _import('santa-data-fixer')
    ])
    const [initPromiseValue, boltDsAdapter, dsViewerManager, dataFixer] = await loadAllModules

    _define('bolt-ds-adapter-amd', [], () => boltDsAdapter)
    _define('bolt-ds-viewer-manager-amd', [], () => dsViewerManager)
    const {initDocumentServicesDocument: initDocument} = boltDsAdapter
    const {hostWithDM, fetchPage, modelsToApplyFromHost} = await initDocument(dataFixer, logger, experimentInst)
    modelsFromHost = modelsToApplyFromHost
    return {...initPromiseValue, fetchPageFromDs: fetchPage, dsHost: hostWithDM}
}

async function initPreviewNonDS() {
    await importGlobals()
    const loadAllModules = Promise.all([
        import(/*webpackChunkName: "init"*/ './init'),
        import(/*webpackChunkName: "bolt-ds-viewer-manager"*/'bolt-ds-viewer-manager')])

    const [initPromiseValue, dsViewerManager] = await loadAllModules
    _define('bolt-ds-viewer-manager-amd', [], () => dsViewerManager)
    return initPromiseValue
}

async function createInit() {
    if (isPreview) {
        if (isDS) {
            return initDocumentServicesDocument()
        }
        return initPreviewNonDS()
    }
    return import(/*webpackChunkName: "init"*/ './init')
}


async function createDocumentServices(hostWithDM, boltInstance) {
    const loadAllModules = Promise.all([
        import(/*webpackChunkName: "bolt-ds-adapter"*/'bolt-ds-adapter'),
        _import('document-services-implementation'),
        _import('coreUtils'),
        _import('document-services-schemas')
    ])
    const [boltDsAdapter, implementation, coreUtils, schemas] = await loadAllModules
    const {createDocumentServices: create} = boltDsAdapter
    const viewerManager = boltInstance.viewerManager
    return create({hostWithDM, viewerManager, implementation, schemas, coreUtils, logger})
}

window.define('experiment', [], () => experimentInst)
generateResourceHints(window, boltBase, rendererModel, serviceTopology, isResponsive, requirejs)

async function fetchFunction(url, options, dataType) {
    const res = await fetch(url, options || undefined)
    if (res.ok || options && options.allowErrors) {
        return res[dataType || 'json']()
    }
    throw res
}

const run = async () => {
    const _ = await _import('lodash')
    const bootstrapPackages = {lodash: _}
    const initPromise = createInit()

    const shouldRecordSession = isDS && experimentInst.isOpen('se_enableGlassboxEditor')
    if (shouldRecordSession) {
        import(/*webpackChunkName: "fullstory-loader"*/ '@wix/wix-fullstory-loader').then(wixFullstoryLoader => {
            wixFullstoryLoader.default({
                label: 'bolt-viewer',
                enableGlassbox: true,
                sample: 0
            })
        })
    }

    const boltAnimationsPromise = import(/*webpackChunkName: "animations"*/ 'bolt-animations/src/warmupAnimations')
    const isBot = rendererModel.seo

    sharedRegistry.init()

    const reportBeatEvent = initBeatEvents(experimentInst, isBot)
    const {beatNumber, eventName} = BEATS.MAIN_R_LOADED
    reportBeatEvent(beatNumber, eventName)
    logger.appLoadingPhaseFinish('mainr_loading')
    generateExtraResourceHints(window, requirejs, experimentInst)

    const {init, createFunctionLibrary, fetchPageFromDs, dsHost} = await initPromise
    const ssrModel = {
        isStreaming,
        isInSSR
    }

    let inHostBatch = false
    let boltInstanceFlush = null

    const hostInstanceBatchingStrategy = function () { // eslint-disable-line func-style
        setImmediate(() => {
            inHostBatch = true
            this.$endBatch()
            inHostBatch = false
            if (boltInstanceFlush) {
                const savedInstanceFlush = boltInstanceFlush
                boltInstanceFlush = null
                savedInstanceFlush()
            }
        })
    }

    const boltInstanceBatchingStrategy = function () { // eslint-disable-line func-style
        if (inHostBatch) {
            boltInstanceFlush = this.$endBatch
        } else {
            this.$endBatch()
        }
    }

    startDSInteraction('mainr-create-function-library')
    const functionLibrary = createFunctionLibrary({
        fetchFunction,
        requireFunction: requirejs,
        workerFunction: createWorkerFactory(isDebug, isInSSR, boltBase),
        workerWrapperIframe: createWorkerWrapperIframe(isDebug, isInSSR, boltBase),
        biReporter: null,
        boltAnimationsPromise,
        logger
    })
    endDSInteraction('mainr-create-function-library')

    if (fetchPageFromDs) {
        functionLibrary.fetchPageFromDs = fetchPageFromDs
    }

    startDSInteraction('mainr-init')
    const {doneStagePromise} = await init({
        logger,
        sentry,
        ssrModel,
        hostInstanceBatchingStrategy,
        boltInstanceBatchingStrategy,
        functionLibrary,
        rendererModel: _.get(modelsFromHost, 'rendererModel') || rendererModel,
        rawSeoTags: undefined,
        documentServicesModel: _.get(modelsFromHost, 'documentServicesModel') || documentServicesModel,
        publicModel,
        isPreview,
        isDS,
        serviceTopology,
        requestModel,
        rawUrl,
        wixBiSession,
        reportBeatEvent,
        registerToIframesLoadEvent: iframesHandler.registerForEvents,
        renderFlags: window.renderFlags || {},
        isBot,
        isDebug,
        isQA,
        santaBase,
        boltBase,
        bootstrapPackages,
        navigationModel: await getNavigationModelFromWarmupData(isStreaming, wixBiSession.isCached, experimentInst)
    })

    const {hostInstance, boltInstance, boltMain, hydrate, serverMarkup, indicator} = await doneStagePromise
    endDSInteraction('mainr-init')
    const isSsrSuccessfulAndSeo = hydrate && rendererModel.seo
    const isFullSeoRenderEnabled = experimentInst.isOpen('bv_support_seo_full_render')
    if (isSsrSuccessfulAndSeo && !isFullSeoRenderEnabled) {
        return
    }
    const rootElement = document.getElementById('SITE_CONTAINER')
    startDSInteraction('mainr-render-client')
    await boltMain.renderClientSide(boltInstance, rootElement, hydrate, serverMarkup, indicator, logger)
    hostInstance.setRenderPhase(hydrate ? 'hydrate' : 'render')
    endDSInteraction('mainr-render-client')

    startDSInteraction('mainr-flush-events-to-iframes')
    iframesHandler.flushEvents()
    boltInstance.setReadyToFlushWindowMessages(true)
    endDSInteraction('mainr-flush-events-to-iframes')
    if (isDS) {
        await createDocumentServices(dsHost, boltInstance)
    }
    hostInstance.setViewerLoaded(true)
    logger.appLoaded()
    window.APP_LOADED = true
}

run().catch(e => {
    console.error(e)
    logger.captureError(e, {tags: {siteLoaded: false}})
})
